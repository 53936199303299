import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Menu, NavItem, Logo, SocialIcon, processNavItems } from '../../index';
import './styles.less';

class NavBar extends Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            showMenu: false,
            mobileMenuHeight: `100%`,
            tabs: props.tabs,
            socialIcons: props.socialIcons
        };
        this.renderSocialIcons = this.renderSocialIcons.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleNavItemClick = this.handleNavItemClick.bind(this);
    }

    componentDidMount() {
        const { cmsVsm, isScrapePage } = this.props;
        if (isScrapePage) {
            fetch(`${cmsVsm}/menuitems/yes/_navs/global-header`)
                .then((res) => res.json())
                .then(
                    (result) => {
                        const { tabs, socialIcons } = processNavItems(result.menuItems);
                        this.setState({
                            tabs,
                            socialIcons
                        });
                    },
                    (error) => {
                        console.error(error);
                    }
                );
        }
    }

    handleClick() {
        this.setState(
            (prevState) => ({
                showMenu: !prevState.showMenu
            }),
            () => {
                if (this.state.showMenu) {
                    document.body.classList.add('menu-open');
                    const headerHeight = document.getElementById('header').clientHeight;
                    const windowInnerHeight = `${window.innerHeight}px`;
                    const mobileMenuHeight = `calc(${windowInnerHeight} - ${headerHeight}px)`;
                    this.setState({ mobileMenuHeight });
                } else {
                    document.body.classList.remove('menu-open');
                }
            }
        );
    }

    handleNavItemClick(text, targetUrl = '') {
        if (typeof window !== 'undefined' && window.digitalData) {
            window.digitalData.event = 'nav-click';
            window.digitalData.action = {
                actionName: `${window.digitalData.currentPage.pageName} : ${text}`,
                interactionType: 'click'
            };
            if (text && text.toUpperCase() === 'WATCH LIVE') {
                window.digitalData.action = Object.assign(window.digitalData.action, {
                    targetUrl,
                    canonicalName: 'Watch Now Click',
                    linkText: text
                });
            }
        }
    }

    renderNavbarItems(tabs, targetTop) {
        const { baseURL } = this.props;
        return tabs.map((tab, key) => {
            return (
                <NavItem
                    navItem={tab}
                    baseURL={baseURL}
                    className={'nav__items--item'}
                    hasDropdown={tab.menuItems.length > 0}
                    key={key}
                    targetTop={targetTop}
                    onClick={this.handleNavItemClick}
                />
            );
        });
    }

    renderSocialIcons(socialIcons) {
        return socialIcons.map((socialIcon, key) => {
            return (
                <SocialIcon
                    className={'nav__social--icons--icon social--icons--icon'}
                    socialName={socialIcon.name}
                    url={socialIcon.url}
                    newWindow={socialIcon.newWindow}
                    key={key}
                    title={socialIcon.title}
                />
            );
        });
    }

    renderMobileMenu(className, tabs, socialIcons) {
        const { baseURL } = this.props;
        return this.state.showMenu === true ? (
            <Menu
                className={className}
                baseURL={baseURL}
                style={{ height: this.state.mobileMenuHeight }}
                tabs={tabs}
                socialIcons={socialIcons}
                renderSocialIcons={this.renderSocialIcons}
            />
        ) : null;
    }

    renderDesktopNav(className, logo, sectionId, socialIcons, tabs, env, watchLive) {
        const targetTop = sectionId === 'thirdparty';
        const watchLiveUrl = "https://gothamsports.com/launch";

        return (
            <nav className={className}>
                <div className="nav__bar--container">
                    <Logo className="nav__image" src={logo} url="/" alt="YES Logo" />
                    <ul className="nav__items">{this.renderNavbarItems(tabs, targetTop)}</ul>
                    {!watchLive && (
                        <Button
                            className={'watch-live'}
                            variant={'watch-live'}
                            centered={true}
                            text={'WATCH LIVE'}
                            url={watchLiveUrl}
                            useLinkAsBtn={true}
                            openInNewTab={true}
                            onClick={() => {
                                this.handleNavItemClick('WATCH LIVE', watchLiveUrl);
                            }}
                        />
                    )}
                    <div className="nav__social--icons social--icons">{this.renderSocialIcons(socialIcons)}</div>
                    <button onClick={this.handleClick} className="nav__hamburger">
                        {this.state.showMenu ? (
                            <Icon className="nav__mobile__burger" icon="close" size="lg" />
                        ) : (
                            <i className="fa fa-bars nav__mobile__burger"></i>
                        )}
                    </button>
                </div>
            </nav>
        );
    }

    render() {
        const { className, env, logo, sectionId, watchLive } = this.props;
        const { tabs, socialIcons } = this.state;

        return (
            <Fragment>
                {this.renderDesktopNav(className, logo, sectionId, socialIcons, tabs, env, watchLive)}
                {this.renderMobileMenu(`${className}__menu`, tabs, socialIcons)}
            </Fragment>
        );
    }
}

NavBar.propTypes = {
    baseURL: PropTypes.string,
    cmsVsm: PropTypes.string,
    className: PropTypes.string,
    env: PropTypes.string,
    isScrapePage: PropTypes.bool,
    logo: PropTypes.string,
    socialIcons: PropTypes.array,
    sectionId: PropTypes.string,
    tabs: PropTypes.array,
    watchLive: PropTypes.bool
};

NavBar.defaultProps = {
    className: 'nav',
    isScrapePage: false,
    tabs: [
        { text: 'yankees', properties: { link: '' } },
        { text: 'nets', properties: { link: '' } },
        { text: 'nycfc', properties: { link: '' } },
        { text: 'liberty', properties: { link: '' } },
        { text: 'shows', properties: { link: '' } },
        { text: 'personalities', properties: { link: '' } },
        { text: 'my yes', properties: { link: '' } }
    ],
    socialIcons: [
        { name: 'facebook', title: 'Follow YES Network on Facebook' },
        { name: 'twitter', title: 'Follow YES Network on Twitter' },
        { name: 'instagram', title: 'Follow YES Network on Instagram' },
        { name: 'envelope-o', title: 'Sign up for YES Network emails' }
    ],
    watchLive: false
};

export default NavBar;
